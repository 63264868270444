<template>
  <div class="sp-wrapper sp-wrapper--right" @keydown.stop="handleKeyDown" id="rsp">
    <div
      v-show="active"
      :aria-hidden="active"
      class="side-panel-mask side-panel-mask--right"
      @click.prevent="deactivate"
    ></div>
    <transition name="slide-fade-right">
      <div
        id="right-side-panel"
        :aria-hidden="!active"
        v-show="active"
        class="side-panel side-panel--right"
        ref="right_side_panel"
      >
        <div class="close close--right">
          <a
            href="#"
            class="close-link close-link--right d-flex justify-content-center align-items-center"
            aria-labelledby="right-side-panel-close-button"
            ref="rsp_close_link"
            @click.stop.prevent="deactivate"
          >
            <span class="icon-close" aria-hidden="true"></span>
            <span class="visually-hidden" id="right-side-panel-close-button">Close button</span>
          </a>
        </div>
        <div class="inner inner--right">
          <div class="rsp-content d-flex flex-column">
            <div class="rsp-content__header">Get help now</div>
            <div class="rsp-content__inner d-flex flex-column">
              <div class="rsp-content-section">
                <div class="rsp-content-heading">In case of emergency</div>
                <div class="rsp-content-block">
                  <div class="rsp-content-block-icon">
                    <span class="icon-phone-exclamation" aria-hidden="true"></span>
                  </div>
                  <div class="rsp-content-block-text">
                    <div class="rsp-content-text">Call the Police</div>
                    <div class="rsp-content-text">
                      <a class="rsp-content-text__link" href="tel:111">111</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="rsp-content-section">
                <div class="rsp-content-divider" aria-hidden="true"></div>
              </div>
              <div class="rsp-content-section">
                <div class="rsp-content-heading">Talk to us</div>
                <div class="rsp-content-block">
                  <div class="rsp-content-block-icon">
                    <span class="icon-phone" aria-hidden="true"></span>
                  </div>
                  <div class="rsp-content-block-text">
                    <div class="rsp-content-text">
                      Call our team of trained counsellors for support 24/7
                    </div>
                    <div class="rsp-content-text">
                      <a href="tel:0800456450" class="rsp-content-text__link">0800 456 450</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="rsp-content-section chat-info-cta">
                <div class="rsp-content-block">
                  <div class="rsp-content-block-icon">
                    <span class="icon-chat-bulb" aria-hidden="true"></span>
                  </div>
                  <div class="rsp-content-block-text">
                    <div class="rsp-content-text">Chat with us 24/7</div>
                  </div>
                </div>
                <div class="rsp-content-cta">
                  <button
                    class="open-live-chat-button button button--medium button--black-filled w-100"
                    @click.prevent.stop="openChat()"
                    @keydown.space.prevent="openChat()"
                  >
                    Open Live Chat
                  </button>
                </div>
              </div>
            </div>
            <div class="rsp-content__footer">
              <button
                class="rsp-close-button button button--large button--purple-filled"
                @click.prevent="deactivate"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'SidePanelRight',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    deactivate() {
      this.$emit('sidePanelRightHide', true);
    },
    openChat() {
      this.deactivate();
      this.$nextTick(() => {
        if (!this.$root.chatOpen) {
          this.$root.toggleChat();
        }
      });
    },
    handleKeyDown(ev) {
      if (!ev.shiftKey) {
        if (ev.key === 'Tab') {
          this.handleTab(ev);
        } else if (ev.key === 'Escape') {
          this.deactivate();
        }
      } else if (ev.key === 'Tab') {
        this.handleShiftTab(ev);
      }
    },
    handleTab(ev) {
      const elements = this.focusableElements();
      const first = elements[0];
      const last = elements[elements.length - 1];

      if (document.activeElement === last) {
        first.focus();
        ev.preventDefault();
      }
    },
    handleShiftTab(ev) {
      const elements = this.focusableElements();
      const first = elements[0];
      const last = elements[elements.length - 1];

      if (document.activeElement === first) {
        last.focus();
        ev.preventDefault();
      }
    },
    focusableElements() {
      const selector = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

      return this.$refs.right_side_panel.querySelectorAll(selector);
    },
  },
};
</script>
