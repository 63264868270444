import axios from 'axios';

class API {
  constructor(client) {
    this.client = client;
    this.abortController = null;
  }

  /**
   * Search
   * Call the search api and return results
   *
   * @param {object} filters - Available filters: tags, age_groups, regions, city, multilingual & refferals
   *  - tags: comma separated string of tags - serviceFeatures
   *  - age_groups: comma separated string of age groups - serviceFeatures
   *  - regions: comma separated string of region
   *  - sub_region: comma separated string of cities/towns
   *  - language: comma separated string of language codes
   *  - services: comma separated string of services - serviceProviders
   *  - programmes: comma separated string of programmes - serviceFeatures
   *  - referrals: boolean does the provider take referrals
   *  - subtypes: comma separated string of service subtypes - serviceFeatures
   *  - access: comma separated string of access types
   *  - help_types: comma separated string of help types
   *
   * @param {string} sortBy - sort result by field (score, name)
   * @param {string} sortOrder - sort results order (asc, desc)
   * @param {int} limit - number of results to return
   * @param {int} offset - offset number of results
   *
   * @return object
   */
  async search({ filters = null, sort_by = null, sort_order = null, limit = null, offset = 0 }) {
    // Abort any existing search calls
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = null;
    }

    this.abortController = new AbortController();
    const config = {
      signal: this.abortController.signal,
      params: {
        ...filters,
        limit,
        offset,
        sort_by,
        sort_order,
      },
    };

    try {
      const { data } = await this.client.get('/s', config);
      this.abortController = null;

      return data;
    } catch (error) {
      return error;
    }
  }

  /**
   * Get Provider
   * Get a single provider by id
   *
   * @param {int} providerId - Provider id
   *
   * @return {object}
   */
  async getProvider(providerId) {
    try {
      const { data } = await this.client.get(`/p/${providerId}`);

      return data;
    } catch {
      return false;
    }
  }

  /**
   * Get Languages
   * Get a list of all available languages
   *
   * @return {array}
   */
  async getLangauages() {
    try {
      const { data } = await this.client.get(`/l`);

      return data
        .map((lang) => {
          return {
            display: lang.display,
            value: lang.code,
          };
        })
        .sort((a, b) => {
          const displayA = a.display.toLowerCase();
          const displayB = b.display.toLowerCase();

          if (displayA < displayB) {
            return -1;
          }

          if (displayA > displayB) {
            return 1;
          }

          return 0;
        });
    } catch (error) {
      return false;
    }
  }

  /**
   * Get Regions
   * Get a list of all regions
   *
   * @return {array}
   */
  async getRegions() {
    try {
      const { data } = await this.client.get(`/r`);

      return data;
    } catch (error) {
      return false;
    }
  }

  /**
   * Get Suicide Prevention Providers
   * Get a list of all suicide prevention providers
   *
   * @return {array}
   */
  async getSuicidePreventionProviders() {
    try {
      const { data } = await this.client.get(`/sp`);

      const tautoko = data.find((p) => p.name === '0508 TAUTOKO');
      const needToTalk = data.find((p) => p.name === '1737, Need to Talk?');
      const lifeline = data.find((p) => p.name === '0800 LIFELINE');

      return [needToTalk, lifeline, tautoko];
    } catch (error) {
      return false;
    }
  }
}

export const getAPIBaseUrl = () => {
  return process.env.VUE_APP_API_BASEURL || window.location.origin;
};

export const httpClient = axios.create({
  baseURL: getAPIBaseUrl(),
});

export const getAPIClient = () => {
  return new API(httpClient);
};

export default {
  install: (app) => {
    app.config.globalProperties.$api = getAPIClient();
  },
};
