<template>
  <div class="multiselect-field">
    <VueMultiselect
      v-model="value"
      ref="selectedItems"
      :options="options"
      :track-by="'value'"
      :multiple="!singleSelect"
      :searchable="searchable"
      :selectLabel="''"
      @keydown.enter.prevent="selectOption"
      @keydown.space.prevent="selectOption"
      @open="isOpen = true"
      @close="isOpen = false"
      :class="[{ 'option-selected': optionSelected }]"
      :close-on-select="singleSelect"
      :custom-label="customLabel"
    >
      <!-- template for placeholder. -->
      <template #placeholder>
        <span :class="`${icon}`"></span>
        {{ placeholder }}
      </template>

      <!-- template for chevron and close. -->
      <template #caret="{ toggle }">
        <div class="multiselect__controls">
          <button
            type="button"
            class="multiselect__chevron icon-chevron-dropdown"
            @mousedown.prevent.stop="toggle"
            @keydown.enter.prevent="toggle"
            aria-label="Toggle dropdown"
            id="msc"
          ></button>
          <div
            v-if="(value && value.length > 0) || (showClearButton && value)"
            class="multiselect__controls--separator"
          >
            <button
              v-if="isOpen"
              type="button"
              class="multiselect__clear--text-only"
              aria-label="Clear selection"
              tabIndex="0"
              @mousedown.prevent="clearSelected"
            >
              Clear
            </button>
            <button
              v-else
              type="button"
              class="multiselect__clear icon-close"
              aria-label="Clear selection"
              @click="clearSelected"
              tabIndex="0"
            ></button>
          </div>
        </div>
      </template>

      <template #noResult>
        <span class="multiselect__no-results">No results found.</span>
      </template>

      <!-- template for options in dropdown list. -->
      <template v-if="singleSelect" #option="{ option }">
        <div class="multiselect__option--single">
          {{ option.display }}
        </div>
      </template>
      <template v-else #option="{ option }">
        <label
          class="multiselect-single-label__checkbox-label"
          :class="{
            'icon-checkbox': !isChecked(option),
            'icon-checkbox-checked': isChecked(option),
          }"
          :for="option.value"
        >
          <input :id="option.value" type="checkbox" aria-hidden="true" />{{ option.display }}
        </label>
      </template>

      <!-- template for showing the selected option/s in input, once selected. -->
      <template v-if="singleSelect" #singleLabel="{ option }">
        <span v-if="icon" :class="`${icon}`"></span>
        {{ option.display }}
      </template>
      <template v-if="!singleSelect" #selection>
        <div v-if="this.value && this.value.length > 0" class="multiselect__tags-wrap">
          <div class="multiselect__tag">
            <div class="multiselect__tag-label">
              <span v-if="icon" :class="`${icon}`"></span>
              {{ sortSelectedOptions() }}
              <span v-if="value.length > 1" class="multiselect__tag-label-count">
                + {{ value.length - 1 }}
              </span>
            </div>
          </div>
        </div>
      </template>
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect';

export default {
  components: {
    VueMultiselect,
  },
  emits: ['update:modelValue', 'clear'],
  props: {
    modelValue: {
      type: undefined,
      required: true,
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    showClearButton: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    optionSelected() {
      if (!this.singleSelect) {
        return !Array.isArray(this.value) && this.value !== null;
      }

      return this.value?.length > 0;
    },
  },
  methods: {
    sortSelectedOptions() {
      const sorted = this.value.map((item) => item.display).sort((a, b) => a.localeCompare(b));
      return sorted[0];
    },
    selectOption($event) {
      this.$refs.selectedItems.addPointerElement($event);
      this.$nextTick(() => {
        this.$refs.selectedItems.$el.focus();
      });
    },
    clearSelected() {
      this.$emit('clear');
      this.$refs.selectedItems.toggle();
    },
    isChecked(option) {
      if (Array.isArray(this.value)) {
        return !!this.value.find((item) => item.value === option.value);
      }

      return false;
    },
    customLabel({ display }) {
      return display;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss">
.multiselect-field .multiselect__content {
  max-height: 300px;
  overflow-y: auto;
}

.multiselect-field .multiselect__content-wrapper {
  overflow: hidden;
}
</style>
