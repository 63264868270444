import { getAPIClient } from '@/api';

/**
 * Manages the search results and any related metadata
 */
export default {
  namespaced: true,
  state: () => ({
    // The current page of results
    page: 1,
    // The total number of results based on the current filters
    totalResults: 0,
    // The results of providers displayed in the Results view
    providerResults: [],
    // The currently selected provider displayed in the Provider view
    currentProvider: {},
    // no-results returned.
    noResults: false,
    // result format grid or list
    resultFormat: 'grid',
  }),
  mutations: {
    setNoResults(state, noResults) {
      state.noResults = noResults;
    },
    setPage(state, pageNum) {
      state.page = pageNum;
    },
    setTotalResults(state, totalResultsNum) {
      state.totalResults = totalResultsNum;
    },
    setProviderResults(state, results) {
      state.providerResults = results;
    },
    setCurrentProvider(state, provider) {
      state.currentProvider = provider;
    },
    setResultFormat(state, format) {
      state.resultFormat = format;
    },
  },
  actions: {
    /**
     * Ensure the currentProvider is set in the store, firstly by attempting to grab the provider from the
     * providerResults, failing that, this function then calls the API to get specific information on a provider
     * @param {ActionContext} [vuexContent]
     * @param {string} providerId
     */
    async setCurrentProvider({ commit, state }, providerId) {
      const client = getAPIClient();

      // Attempt to get the provider from the existing results in the store first
      let { provider } =
        // eslint-disable-next-line no-shadow
        state.providerResults.find(({ provider }) => {
          return provider.id === providerId;
        }) || {};

      // If currentProvider doesn't exist in the store already then we need to fetch it
      if (!provider) {
        provider = await client.getProvider(providerId);
      }

      commit('setCurrentProvider', provider);
    },
  },
};
