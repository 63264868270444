<template>
  <div class="back">
    <a id="back" href="/" @click.prevent="$router.back()" class="icon-back">Back</a>
  </div>
</template>

<script>
export default {
  name: 'BackComponent',
};
</script>
