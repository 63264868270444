<template>
  <div class="skip-links visually-hidden-focusable">
    <a class="skip-links__item" href="#site-content">Skip to main content</a>
  </div>
</template>

<script>
export default {
  name: 'SkipLinksComponent',
};
</script>
