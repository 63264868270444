import { cloneDeep } from 'lodash-es';
import { TOWNS_CITIES } from '@/constants/locations';
import { getAPIClient } from '@/api';

/**
 * Manages current setting / filter values.
 *
 * Filters for the Results view are stored in state twice, to track the
 * current field values separately from the last searched values. This
 * enables us to discard changes to the fields when necessary (in the
 * tablet and mobile interface.)
 */
export default {
  namespaced: true,
  state: () => ({
    // Filters exposed in Home view
    helpTypes: [],
    impactingChildren: false,
    keyword: {
      live: null,
      applied: null,
    },
    regions: [],
    languages: [],
    // Filters exposed in Results view
    // Selected in the UI (represents live field state)
    selected: {
      region: null,
      location: [],
      languages: [],
      serviceFeatures: [],
      servicesProvided: [],
      impactingChildren: false,
      openNow: false,
      serviceTypes: [],
      otherTags: [],
      helpTypes: [],
    },
    // Applied to the search query (represents state as of last search)
    applied: {
      region: null,
      location: [],
      languages: [],
      serviceFeatures: [],
      servicesProvided: [],
      impactingChildren: false,
      openNow: false,
      serviceTypes: [],
      otherTags: [],
      helpTypes: [],
    },
    sortBy: 'score',
    orderBy: 'asc',
    perPage: { value: 20, label: '20' },
  }),
  actions: {
    async fetchRegions({ commit }) {
      const client = getAPIClient();
      const regions = await client.getRegions();

      commit('setRegions', regions);
    },
    async fetchLanguages({ commit }) {
      const client = getAPIClient();
      const languages = await client.getLangauages();

      commit('setLanguages', languages);
    },
  },
  mutations: {
    setRegions(state, regions) {
      state.regions = regions;
    },

    setLanguages(state, languages) {
      state.languages = languages;
    },

    setSelectedLanguages(state, language) {
      state.selected.languages = language;
    },

    setSelectedServiceFeatures(state, feature) {
      state.selected.serviceFeatures = feature;
    },

    setSelectedServicesProvided(state, service) {
      state.selected.servicesProvided = service;
    },

    setOpenNow(state, openNow) {
      state.selected.openNow = openNow;
    },

    setRegion(state, region) {
      state.selected.region = region;
    },

    setKeyword(state, keyword) {
      state.keyword.live = keyword;
    },

    setOrderBy(state, orderBy) {
      state.orderBy = orderBy;
    },

    setSortBy(state, sortBy) {
      state.sortBy = sortBy;
    },

    setPerPage(state, perPage) {
      state.perPage = perPage;
    },

    setHelpTypes(state, helpTypes) {
      state.selected.helpTypes = helpTypes;
    },

    setImpactingChildren(state, isImpactingChildren) {
      state.selected.impactingChildren = isImpactingChildren;
    },

    applyHelpTypes(state, helpTypes) {
      state.applied.helpTypes = helpTypes;
    },

    // This is only called from Home page setter to give the default values for other modal/panel
    selectHelpTypes(state, helpTypes) {
      state.selected.helpTypes = helpTypes;
      state.applied.helpTypes = helpTypes;
    },

    applyImpactingChildren(state, isImpactingChildren) {
      state.applied.impactingChildren = isImpactingChildren;
    },

    // This is only called from Home page setter to give the default value for other modal/panel
    selectImpactingChildren(state, isImpactingChildren) {
      state.selected.impactingChildren = isImpactingChildren;
      state.applied.impactingChildren = isImpactingChildren;
    },

    setLocation(state, location) {
      state.selected.location = location;
    },

    setServiceTypes(state, serviceTypes) {
      state.selected.serviceTypes = serviceTypes;
    },

    setOtherTags(state, otherTags) {
      state.selected.otherTags = otherTags;
    },

    removeKeyword(state) {
      state.keyword.live = null;
    },

    removeLocation(state) {
      state.selected.location = [];
    },

    removeLanguages(state) {
      state.selected.languages = [];
    },

    removeServiceFeatures(state) {
      state.selected.serviceFeatures = [];
    },

    removeServicesProvided(state) {
      state.selected.servicesProvided = [];
    },

    removeOpenNow(state) {
      state.selected.openNow = false;
    },

    removeServiceType(state, serviceType) {
      const index = state.selected.serviceTypes.indexOf(serviceType);

      if (index > -1) {
        state.selected.serviceTypes.splice(index, 1);
      }
    },

    removeOtherTag(state, otherTag) {
      const index = state.selected.otherTags.indexOf(otherTag);

      if (index > -1) {
        state.selected.otherTags.splice(index, 1);
      }
    },

    /**
     * Applies changes to live keyword since last search.
     *
     * @param state
     */
    applyKeywordChanges(state) {
      state.keyword.applied = state.keyword.live;
    },

    /**
     * Applies changes to selected filter values since last search.
     *
     * @param state
     */
    applyResultFilterChanges(state) {
      state.applied = cloneDeep(state.selected);
    },

    /**
     * Discards changes to selected filter values since last search.
     * Used when the user closes the preferences modal on mobile/tablet.
     *
     * @param state
     */
    discardResultFilterChanges(state) {
      state.selected = cloneDeep(state.applied);
    },

    /**
     * Resets filter fields exposed in the Results view, avoiding those exposed
     * in the Home view as this would cause confusing UX.
     *
     * @param state
     */
    clearResultFilters(state) {
      state.selected.location = [];
      state.selected.impactingChildren = false;
      state.selected.openNow = false;
      state.selected.serviceTypes = [];
      state.selected.otherTags = [];
      state.selected.helpTypes = [];

      state.applied = cloneDeep(state.selected);
    },

    /**
     * Resets all fields in Home view
     *
     * @param state
     */
    clearHome(state) {
      state.selected.helpTypes = [];
      state.selected.impactingChildren = null;

      state.applied.helpTypes = [];
      state.applied.impactingChildren = null;

      state.keyword.live = null;
      state.keyword.applied = null;
    },

    /**
     * Resets the search
     *
     * @param state
     */
    clearSearch(state) {
      state.keyword.live = null;
      state.keyword.applied = null;
    },
  },
  getters: {
    /**
     * Return all cities/towns based on a given region
     */
    getCityOptions: (state) => {
      if (!state.selected.region) {
        return [];
      }
      const cities = state.regions.find((region) => {
        return region.region === state.selected.region.value;
      })?.cities;

      return cities.map((city) => {
        return {
          value: city,
          display: city,
        };
      });
    },

    getSelectedLocations(state) {
      return state.selected.location.map((location) => location.value);
    },

    getSelectedLanguages(state) {
      return state.selected.languages.map((languages) => languages.value);
    },

    getServiceTypes(state) {
      return state.selected.servicesProvided
        .filter((service) => service.value.service_types?.length > 0)
        .map((service) => service.value.service_types.join(','));
    },

    getSubTypes(state) {
      return state.selected.servicesProvided
        .filter((service) => service.value.subtypes?.length > 0)
        .map((service) => service.value.subtypes.join(','));
    },

    getAgeGroups(state) {
      return state.selected.servicesProvided
        .filter((service) => service.value.age_groups?.length > 0)
        .map((service) => service.value.age_groups.join(','));
    },

    getProgrammes(state) {
      return state.selected.servicesProvided
        .filter((service) => service.value.programme_areas?.length > 0)
        .map((service) => service.value.programme_areas.join(','));
    },

    getProvidedTags(state) {
      return state.selected.servicesProvided
        .filter((service) => service.value.tags?.length > 0)
        .map((service) => service.value.tags?.join(','));
    },

    getAccess(state) {
      return state.selected.serviceFeatures
        .filter((service) => service.value.access?.length > 0)
        .map((service) => service.value.access.join(','));
    },

    getFeaturedTags(state) {
      return state.selected.serviceFeatures
        .filter((service) => service.value.tags?.length > 0)
        .map((service) => service.value.tags.join(','));
    },

    /**
     * Return all regions in format suitable for being dropdown options
     */
    getRegionOptions(state) {
      return state.regions.sort().map((region) => {
        return {
          value: region.region,
          display: region.region,
        };
      });
    },

    /**
     * Return all languages in format suitable for being dropdown options
     */
    getLanguages(state) {
      return state.languages.map((language) => {
        return {
          value: language.code,
          display: language.display,
        };
      });
    },

    /**
     * Calculates how many filters have been applied.
     * Only counts filters available in the Results view.
     *
     * @param state
     * @returns {number}
     */
    appliedFilterTally(state) {
      let tally = 0;

      // Handle count of helpTypes
      tally += state.selected.helpTypes.length;

      // Add 1 if impactingChildren is true
      if (state.selected.impactingChildren) {
        tally += 1;
      }

      // Handle languages
      if (state.selected.languages.length) {
        tally += 1;
      }

      // Handle serviceFeatures
      if (state.selected.serviceFeatures.length) {
        tally += 1;
      }

      // Handle servicesProvided
      if (state.selected.servicesProvided.length) {
        tally += 1;
      }

      // Handle regions
      if (state.selected.region) {
        tally += 1;
      }

      // Handle location (city/town)
      if (state.selected.location.length) {
        tally += 1;
      }

      // Handle openNow
      if (state.selected.openNow) {
        tally += 1;
      }

      return tally;
    },

    /**
     * Calculates how many helpTypes have been applied.
     * Only counts filters available in the Results view.
     *
     * @param state
     * @returns {number}
     */
    appliedHelpTypesTally(state) {
      let tally = 0;

      // Handle count of helpTypes
      tally += state.selected.helpTypes.length;

      // Add 1 if impactingChildren is true
      if (state.selected.impactingChildren) {
        tally += 1;
      }

      return tally;
    },

    /**
     * Compiles the co-ordinates for the currently applied location.
     *
     * @param state
     * @returns {string|null}
     */
    coordinatesForLocation(state) {
      if (!state.applied.location.length) {
        return null;
      }

      const selectedLocation = TOWNS_CITIES.find(
        (location) => location.name === state.applied.location
      );

      return `${selectedLocation?.latitude},${selectedLocation?.longitude}`;
    },

    /**
     * Structures filter values as query parameters to be used in an API request.
     *
     * @param {object} state
     */
    queryParameters(state, getters) {
      const Tags = [...getters.getProvidedTags, ...getters.getFeaturedTags];

      // eslint-disable-next-line
      let region = state.selected.region;

      if (region) {
        if (region.value === "Hawke's Bay") {
          region = 'Hawkes Bay';
        } else {
          region = region.value;
        }
      }

      return {
        keyword: state.keyword.live,
        regions: region,
        sub_region: getters.getSelectedLocations.join(','),
        language: getters.getSelectedLanguages.join(','),
        subtypes: getters.getSubTypes.join(','),
        access: getters.getAccess.join(','),
        services: getters.getServiceTypes.join(','),
        age_group: getters.getAgeGroups.join(','),
        open_now: state.selected.openNow ? '1' : null,
        tags: Tags.join(','),
        impacting_children: state.selected.impactingChildren ? '1' : null,
        help_types: state.selected.helpTypes.join(','),
        programmes: getters.getProgrammes.join(','),
      };
    },
  },
};
