/**
 * Options for the "How can we help you?" field in the Home view
 */
import * as tags from '@/constants/tags';

export const HELP_TYPE_OPTIONS = [
  {
    label: "I don't feel safe and I don't know what to do",
    value: tags.UNSAFE,
  },
  {
    label: 'I want to talk to someone about sexual harm',
    value: tags.SEXUAL_HARM,
  },
  {
    label: 'I want relationship advice',
    value: tags.RELATIONSHIP_ADVICE,
  },
  {
    label: 'I need to talk things through with someone',
    value: tags.NEED_TO_TALK,
  },
  {
    label: "I'd like mental health and wellbeing support",
    value: tags.MENTAL_HEALTH,
  },
  {
    label: "I'm concerned there are abusive behaviours in my relationship",
    value: tags.ABUSIVE_RELATIONSHIP,
  },
  {
    label: 'I want support to work through addiction',
    value: tags.WORKING_THROUGH_ADDICTION,
  },
  {
    label: "I'd like help to understand and manage my anger",
    value: tags.ANGER_MANAGEMENT,
  },
  {
    label: "I'm worried I'm frightening or hurting others",
    value: tags.WORRIED,
  },
  {
    label: "I'm thinking about harming myself",
    value: tags.HARM_MYSELF,
  },
];

/**
 * Options for the "Service type" preference options in the Results view
 */
export const SERVICE_TYPE_OPTIONS = [
  {
    label: 'Free',
    value: tags.FREE,
  },
  {
    label: 'Face to face / Kanohi ki te kanohi',
    value: tags.FACE_TO_FACE,
  },
  {
    label: 'Online services',
    value: tags.ONLINE,
  },
  {
    label: 'Phone services',
    value: tags.PHONE,
  },
  {
    label: 'Available 24/7',
    value: tags.TWENTY_FOUR_SEVEN,
  },
  {
    label: 'Speak with women',
    value: tags.SPEAK_WITH_WOMEN,
  },
  {
    label: 'Speak with men',
    value: tags.SPEAK_WITH_MEN,
  },
  {
    label: 'No referral needed',
    value: tags.NO_REFERRALS,
  },
  {
    label: 'Multilingual',
    value: tags.MULTILINGUAL,
  },
];

/**
 * Options for the "Other" preference options in the Results view
 */
export const OTHER_OPTIONS = [
  {
    label: 'Children friendly',
    value: tags.CHILD_FRIENDLY,
  },
  {
    label: 'LGBTQIA+/Rainbow/Takatāpui friendly',
    value: tags.LGBTQIA_FRIENDLY,
  },
  {
    label: 'Wheelchair access',
    value: tags.WHEELCHAIR_ACCESS,
  },
  {
    label: 'NZ sign language interpreter',
    value: tags.SIGN_LANGUAGE,
  },
  {
    label: 'Quiet, low sensory environment',
    value: tags.QUIET_ENVIRONMENT,
  },
  {
    label: 'A longer appointment time',
    value: tags.LONG_APPOINTMENTS,
  },
  {
    label: 'Support to make decisions',
    value: tags.DECISION_SUPPORT,
  },
  {
    label: 'Assistance to move around',
    value: tags.MOVEMENT_ASSISTANCE,
  },
  {
    label: 'More space to move around',
    value: tags.SPACIOUS,
  },
];
