<template>
  <div class="home">
    <form @submit.prevent="getResult" class="landing-page-form form container">
      <div
        class="form-header-wrapper d-flex flex-column flex-lg-row align-items-center justify-content-between"
      >
        <div class="form-header d-flex flex-column row-gap-1">
          <h1 class="form-header__heading mt-0 mb-0">How can we help you?</h1>
          <legend class="form-header__text">
            Use the search bar <span class="legend-OR">OR</span> select as many answers as you need
            below to see a list of available services
          </legend>
        </div>
        <SearchField wrapper-class="search-field-landing-page d-none d-lg-block" />
        <SearchField
          wrapper-class="search-field-landing-page d-flex d-lg-none flex-column align-items-center row-gap-1"
          place-holder-text="Search for services"
          :show-help-text="true"
        />
      </div>
      <div class="form-fields form-fields--home-page d-flex flex-column align-items-center">
        <HelpTypeFieldset wrapper-class="fieldset-checkbox d-flex flex-column flex-lg-row">
        </HelpTypeFieldset>
        <div class="composite-field-location d-flex flex-column flex-lg-row">
          <div class="composite-field-location-header d-flex flex-column">
            <div class="composite-field-location-label">Location</div>
            <div class="location-description location-description--desktop d-none d-lg-block">
              <span class="icon-information" aria-hidden="true"></span>
              Your location will only be used to improve your search. It will not be stored once you
              exit the tool.
            </div>
          </div>
          <LocationFields
            region-select-icon="icon-pin-map"
            region-select-placeholder="Select a Region"
            region-field-label="Region"
            city-select-icon="icon-geo"
            city-select-placeholder="Select a Town"
            city-field-label="Town"
            wrapper-class="location-field--landing-page d-flex flex-column"
            region-field-class="d-flex flex-column"
            city-field-class="d-flex flex-column"
          ></LocationFields>
          <div class="composite-field-location-footer d-block d-lg-none">
            <div class="location-description location-description--mobile">
              <span class="icon-information" aria-hidden="true"></span>
              Your location will only be used to improve your search in the area. It will remain
              confidential and not be stored once you leave the site.
            </div>
          </div>
        </div>
      </div>
      <div class="form-submit">
        <button
          @click.prevent="getResult"
          class="button-view-result button button--purple-filled button--large"
        >
          View result
        </button>
        <router-link :to="{ name: 'results' }" @click="handleSkip" class="skip-to-search-link">
          or skip the questions and search the full list.
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import SearchField from '@/components/SearchField.vue';
import HelpTypeFieldset from '@/components/HelpTypeFieldset.vue';
import LocationFields from '@/components/LocationFields.vue';

export default {
  name: 'HomeView',
  components: {
    SearchField,
    HelpTypeFieldset,
    LocationFields,
  },
  computed: {
    helpTypes: {
      get() {
        return this.$store.state.filters.selected.helpTypes;
      },
    },
    impactingChildren: {
      get() {
        return this.$store.state.filters.selected.impactingChildren;
      },
    },
    region: {
      get() {
        return this.$store.state.filters.selected.region;
      },
    },
    sub_region: {
      get() {
        return this.$store.state.filters.selected.location;
      },
    },
    keyword: {
      get() {
        return this.$store.state.filters.keyword.live;
      },
    },
  },
  created() {
    // Empty results in case the user has returned from viewing the results page to ensure we re-fetch upon submission
    this.$store.commit('results/setPage', 0);
    this.$store.commit('results/setProviderResults', []);
  },
  methods: {
    getResult() {
      this.applyKeywordChanges();

      this.sendGTMEvent('home_view_results', {
        keyword: this.keyword,
        help_types: this.helpTypes.length ? this.helpTypes.join(',') : '',
        impacting_children: !!this.impactingChildren,
        regions: this.region?.value,
        sub_region: this.sub_region.length
          ? this.sub_region
              .map((city) => {
                return city.value;
              })
              .join(', ')
          : '',
      });

      this.$router.push('/results');
    },
    handleSkip() {
      this.clearHome();
      this.sendGTMEvent('home_skip_questions');
    },
    ...mapMutations('filters', ['clearHome', 'clearSearch', 'applyKeywordChanges']),
  },
};
</script>
