<template>
  <div>
    <transition name="fade" mode="out-in" v-cloak>
      <div class="chat-overlay" v-if="$root.chatOpen">
        <focus-trap>
          <div
            id="chat-popup"
            class="chat-popup chat-popup--overlay"
            role="dialog"
            aria-modal="true"
            aria-labelledby="chat-popup-title"
            @keydown.esc="toggleChat()"
          >
            <div class="chat-popup__title-bar">
              <h2 id="chat-popup-title" class="chat-popup__title">Live chat</h2>
              <button
                aria-label="End chat"
                @click.prevent="toggleChat()"
                @keydown.space.prevent="toggleChat()"
                class="icon-close-white close-button"
              ></button>
            </div>
            <iframe
              allowtransparency="true"
              title="Chat window"
              id="chat-iframe"
              ref="chatFrame"
              class="chat-popup__iframe"
              :src="chaturl"
            >
            </iframe>
          </div>
        </focus-trap>
      </div>
    </transition>
  </div>
</template>

<script>
import { FocusTrap } from 'focus-trap-vue';
import { nextTick } from 'vue';

export default {
  name: 'chat-popup',
  props: ['chaturl', 'mobiletitle', 'desktoptitle', 'chatpopup'],
  components: {
    'focus-trap': FocusTrap, // The focus trap doesn't appear to work across frames
  },
  methods: {
    // Open/close the chat
    toggleChat() {
      // Check if popup should open in iframe
      if (this.$props.chatpopup === '1') {
        this.$root.chatOpen = !this.$root.chatOpen;

        document.querySelector('body').classList.toggle('overlay-open');

        // If chat popup is open, focus into iframe
        // Note that we can't detect a keypress of esc to close this content
        // as it's in an iframe on a different domain
        if (this.$root.chatOpen) {
          nextTick(() => {
            document.getElementById('chat-iframe').focus();
          });
        }

        return;
      }

      // Open chat in new window
      window.open(this.$props.chaturl, 'chatWindow', 'height=500,width=400');
    },

    // Closes the chat
    closeChat() {
      this.$root.chatOpen = false;
    },
  },
  computed: {
    // Get the mobile title
    getMobileTitle() {
      return this.$root.chatOpen ? 'End' : this.$props.mobiletitle;
    },
    // Get the desktop title
    getDesktopTitle() {
      return this.$root.chatOpen ? 'End chat' : this.$props.desktoptitle;
    },
    // Set a minimum width so the button doesn't get smaller when showing 'End chat'
    getButtonWidth() {
      return this.$root.chatOpen ? this.$refs.chatButton.offsetWidth : 0;
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', (event) => {
      if (this.$root.chatOpen) {
        // Show a message in the browser if the user tries to leave the page
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = ''; // eslint-disable-line no-param-reassign
      }
    });
  },
};
</script>
