<template>
  <div class="location-field" :class="wrapperClass">
    <div class="location-field__region" :class="regionFieldClass">
      <label class="location-field__label" for="regionSelectField">
        {{ regionFieldLabel }}
      </label>
      <!-- Region -->
      <MultiSelectField
        id="regionSelectField"
        :class="'region-selection'"
        :options="getRegionOptions"
        v-model="region"
        @clear="clearSelectedRegion"
        :icon="regionSelectIcon"
        :placeholder="regionSelectPlaceholder"
        :single-select="true"
        :show-clear-button="true"
      >
      </MultiSelectField>
    </div>
    <div class="location-field__town" :class="cityFieldClass">
      <label
        class="location-field__label"
        :class="{ disabled: getCityOptions.length === 0 }"
        for="citySelectField"
      >
        {{ cityFieldLabel }}
      </label>
      <!-- Cities -->
      <MultiSelectField
        :key="`cities-${getCityOptions.length}`"
        id="citySelectField"
        :class="{ 'town-selection': true, disabled: getCityOptions.length === 0 }"
        :options="getCityOptions"
        :placeholder="citySelectPlaceholder"
        :icon="citySelectIcon"
        :aria-hidden="getCityOptions.length === 0"
        :single-select="false"
        v-model="location"
        @clear="clearSelectedLocation"
      ></MultiSelectField>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MultiSelectField from './MultiSelectField.vue';

export default {
  name: 'LocationFields',
  components: {
    MultiSelectField,
  },
  props: {
    wrapperClass: {
      type: String,
      default: '',
    },
    regionFieldClass: {
      type: String,
      default: '',
    },
    cityFieldClass: {
      type: String,
      default: '',
    },
    regionSelectIcon: {
      type: String,
      default: '',
    },
    regionSelectPlaceholder: {
      type: String,
      default: 'Select a Region',
    },
    regionFieldLabel: {
      type: String,
      default: '',
    },
    citySelectIcon: {
      type: String,
      default: '',
    },
    citySelectPlaceholder: {
      type: String,
      default: 'Select a Town',
    },
    cityFieldLabel: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      getCityOptions: 'filters/getCityOptions',
      getRegionOptions: 'filters/getRegionOptions',
    }),
    region: {
      get() {
        return this.$store.state.filters.selected.region;
      },
      set(value) {
        // Clear location when region changes.
        this.location = [];
        this.$store.commit('filters/setRegion', value);
      },
    },
    location: {
      get() {
        return this.$store.state.filters.selected.location;
      },
      set(value) {
        this.$store.commit('filters/setLocation', value);
      },
    },
  },
  methods: {
    clearSelectedLocation() {
      this.location = [];
    },
    clearSelectedRegion() {
      this.region = null;
      this.location = [];
    },
    clearAll() {
      this.clearSelectedRegion();
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
