import { createRouter, createWebHistory } from 'vue-router';

import Home from '@/views/Home.vue';

const DEFAULT_TITLE = 'Service Finder';

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { title: `Home` },
    component: Home,
  },
  {
    path: '/results',
    name: 'results',
    meta: { title: 'Results' },
    component: () => import(/* webpackChunkName: "results" */ '@/views/Results.vue'),
  },
  {
    path: '/about',
    name: 'about',
    meta: { title: 'About' },
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
  },
  {
    path: '/button',
    name: 'button',
    meta: { title: 'button' },
    component: () => import(/* webpackChunkName: "button" */ '@/views/Button.vue'),
  },
  {
    path: '/using-the-tool-safely',
    name: 'using-the-tool-safely',
    meta: { title: 'Using the Tool Safely' },
    component: () =>
      import(/* webpackChunkName: "using-the-tool-safely" */ '@/views/UsingTheToolSafely.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    meta: { title: 'Privacy Policy' },
    component: () => import(/* webpackChunkName: "privacy-policy" */ '@/views/PrivacyPolicy.vue'),
  },
  {
    path: '/copyright',
    name: 'copyright',
    meta: { title: 'Copyright' },
    component: () => import(/* webpackChunkName: "copyright" */ '@/views/Copyright.vue'),
  },
  {
    path: '/provider/:id',
    name: 'provider',
    meta: { title: 'Provider' },
    component: () => import(/* webpackChunkName: "provider" */ '@/views/ServiceProvider.vue'),
  },
  {
    path: '/embedding',
    name: 'embedding',
    meta: { title: 'Embedding the Tool' },
    component: () => import(/* webpackChunkName: "embedding" */ '@/views/Embedding.vue'),
  },
  {
    path: '/404',
    name: '404',
    meta: { title: 'Page not found' },
    component: () => import(/* webpackChunkName: "PageNotFound" */ '@/views/PageNotFound.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page-not-found',
    redirect: { name: '404' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    let scroll = {
      top: 0,
    };

    if (savedPosition) {
      scroll = savedPosition;
    } else if (to.hash) {
      scroll = {
        el: to.hash,
      };
    }

    return scroll;
  },
  routes,
});

router.afterEach((to) => {
  document.title = `${to.meta.title} - ${DEFAULT_TITLE}`;
});

// Only allow access to button page, if in development.
const isDevelopment = process.env.NODE_ENV === 'development';

router.beforeResolve((to) => {
  if (to.path === '/button' && !isDevelopment) {
    return false;
  }
  return true;
});

export default router;
