<template>
  <VueAnnouncer class="sr-only" />
  <div @keydown="this.focusTrapEnabled = true" @click="this.focusTrapEnabled = false">
    <focus-trap :active="focusTrapEnabled" :return-focus-on-deactivate="false">
      <div>
        <SkipLinks />
        <chatPopup
          ref="chatPopup"
          chaturl="https://helpline.homecaremedical.co.nz/helpisavailable"
          mobiletitle="live chat mobile"
          desktoptitle="live chat desktop"
          chatpopup="1"
        ></chatPopup>
        <SidePanelRight
          :active="sidePanelRightShow"
          @side-panel-right-hide="hideSidePanelRight"
          ref="side_panel_right"
        />
        <Header />
        <main id="site-content" :class="view">
          <Back
            v-if="
              currentRouteName !== 'home' &&
              currentRouteName !== 'results' &&
              currentRouteName !== 'provider'
            "
          />
          <router-view :refresh="fetch" @fetched="onFetched" :key="$route.fullPath" />
        </main>
        <Footer @side-panel-right-show="showSidePanelRight" ref="footer" />
      </div>
    </focus-trap>
  </div>
</template>

<script>
import SkipLinks from '@/components/SkipLinks';
import SidePanelRight from '@/components/SidePanelRight';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Back from '@/components/Back';
import ChatPopup from '@/components/ChatPopup';
import { FocusTrap } from 'focus-trap-vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { mapState } from 'vuex';

export default {
  name: 'App',
  components: {
    FocusTrap,
    SkipLinks,
    SidePanelRight,
    Header,
    Footer,
    Back,
    ChatPopup,
  },
  data() {
    return {
      focusTrapEnabled: true,
      fetch: false,
      sidePanelRightShow: false,
      chatOpen: false,
    };
  },
  computed: {
    view() {
      return `view-${this.$route.name}`;
    },
    ...mapState({
      regions: (state) => state.filters.regions,
      languages: (state) => state.filters.languages,
    }),
  },
  mounted() {
    // Send gtm event if embedded into a site
    if (this.isEmbedded()) {
      window.addEventListener('message', (event) => {
        if (event.data && event.data.embedded_from) {
          this.sendGTMEvent('embedded', { domain: event.data.embedded_from });
        }
      });
    }

    this.$store.dispatch('filters/fetchRegions');
    this.$store.dispatch('filters/fetchLanguages');
  },
  methods: {
    onFetched() {
      this.fetch = false;
    },
    fetchFreshResults() {
      this.fetch = true;
    },
    /**
     * Sends a message outside the iframe containing the Service Finder Tool to close it.
     * See the embed/main.js file for where the message is received.
     */
    exitTool() {
      if (this.isEmbedded()) {
        // We don't specify the domain here as the widget could exist on any site,
        // this is okay though because we are not sending any confidential information
        window.parent.postMessage('closeServiceFinderTool', '*');
      }
    },
    showSidePanelRight() {
      this.sidePanelRightShow = true;

      this.$nextTick(() => {
        document.querySelector('body').classList.toggle('overlay-open');
        this.$refs.side_panel_right.$refs.rsp_close_link.focus();
      });
    },
    hideSidePanelRight() {
      this.sidePanelRightShow = false;
      this.$nextTick(() => {
        document.querySelector('body').classList.toggle('overlay-open');
        this.$refs.footer.$refs.get_help_now_button.focus();
      });
    },
    toggleChat() {
      this.$refs.chatPopup.toggleChat();
    },
  },
  setup() {
    const currentRouteName = computed(() => useRoute().name);

    return { currentRouteName };
  },
};
</script>
