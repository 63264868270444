<template>
  <fieldset :class="wrapperClass">
    <template v-for="(option, index) in helpTypeOptions">
      <label class="checkbox" v-if="index < 8" :key="index">
        <input
          type="checkbox"
          :id="'helpType' + index"
          name="helpTypes[]"
          v-model="helpTypes"
          :value="option.value"
        />
        <span class="background"></span>
        <span class="checkmark"></span>
        {{ option.label }}
      </label>
    </template>
    <label class="checkbox">
      <input
        type="checkbox"
        name="impactingChildren"
        id="impactingChildren"
        v-model="impactingChildren"
      />
      <span class="background"></span>
      <span class="checkmark"></span>
      I want support for a child or young person
    </label>
    <template v-for="(option, index) in helpTypeOptions">
      <label class="checkbox" v-if="index > 7" :key="index">
        <input
          type="checkbox"
          :id="'helpType' + index"
          name="helpTypes[]"
          v-model="helpTypes"
          :value="option.value"
          v-if="index > 7"
        />
        <span class="background"></span>
        <span class="checkmark"></span>
        {{ option.label }}
      </label>
    </template>
    <div v-if="inSidePanel" class="help-types-footer"></div>
  </fieldset>
</template>

<script>
import { HELP_TYPE_OPTIONS } from '@/constants/filters';

export default {
  name: 'HelpTypeFieldset',
  props: {
    inSidePanel: {
      type: Boolean,
      default: false,
    },
    wrapperClass: {
      type: String,
      required: false,
    },
  },
  computed: {
    helpTypes: {
      get() {
        return this.$store.state.filters.selected.helpTypes;
      },
      set(value) {
        this.$store.commit('filters/selectHelpTypes', value);
      },
    },
    impactingChildren: {
      get() {
        return this.$store.state.filters.selected.impactingChildren;
      },
      set(value) {
        this.$store.commit('filters/selectImpactingChildren', value);
      },
    },
  },
  data() {
    return {
      helpTypeOptions: HELP_TYPE_OPTIONS,
    };
  },
};
</script>
