export const SERVICES_FEATURED = [
  {
    display: 'Available 24/7',
    value: {
      tags: ['24/7'],
      access: [],
    },
  },
  {
    display: 'Free',
    value: {
      tags: ['free'],
      access: [],
    },
  },
  {
    display: 'Home visit provided',
    value: {
      tags: [],
      access: ['Home visit provider'],
    },
  },
  {
    display: 'Make an appointment',
    value: {
      tags: [],
      access: ['Make an appointment'],
    },
  },
  {
    display: 'No appointment needed',
    value: {
      tags: [],
      access: ['No appointment needed'],
    },
  },
  {
    display: 'Walk In',
    value: {
      tags: [],
      access: ['Walk in'],
    },
  },
  {
    display: 'Wheelchair access',
    value: {
      tags: ['Wheelchair access'],
      access: [],
    },
  },
  {
    display: 'Speak with Women',
    value: {
      tags: ['speak with women / wahine'],
      access: [],
    },
  },
  {
    display: 'Speak with Men',
    value: {
      tags: ['speak with men / tane'],
      access: [],
    },
  },
];
