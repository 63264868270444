/**
 * Constants representing tags exposed by the API. Non-exhaustive - only tags
 * that are explicitly used in front-end code are represented.
 */

// Help types
export const WORRIED = 'worried';
export const UNSAFE = 'unsafe';
export const SEXUAL_HARM = 'sexual-harm';
export const RELATIONSHIP_ADVICE = 'relationship-advice';
export const NEED_TO_TALK = 'need-to-talk';
export const ABUSIVE_RELATIONSHIP = 'abusive-relationship';
export const MENTAL_HEALTH = 'mental-health';
export const WORKING_THROUGH_ADDICTION = 'working-through-addiction';
export const ANGER_MANAGEMENT = 'anger-management';
export const HARM_MYSELF = 'worried,need-to-talk,mental-health';

// Service types
export const FREE = 'free';
export const FACE_TO_FACE = 'face to face / kanohi ki te kanohi';
export const ONLINE = 'online / virtual / app';
export const PHONE = 'phone';
export const TWENTY_FOUR_SEVEN = '24/7';
export const SPEAK_WITH_WOMEN = 'speak with women / wahine';
export const SPEAK_WITH_MEN = 'speak with men / tane';
export const NO_REFERRALS = 'no referrals';
export const MULTILINGUAL = 'multilingual';

// Other options
export const CHILD_FRIENDLY = 'child / tamariki friendly';
export const LGBTQIA_FRIENDLY = 'lgbtqia+ friendly';
export const WHEELCHAIR_ACCESS = 'wheelchair access';
export const SIGN_LANGUAGE = 'nz sign language interpreter';
export const QUIET_ENVIRONMENT = 'quiet low sensory environment';
export const LONG_APPOINTMENTS = 'a longer appointment time';
export const DECISION_SUPPORT = 'support to make decisions';
export const MOVEMENT_ASSISTANCE = 'assistance to move around';
export const SPACIOUS = 'more space to move around';
