export const SERVICES_PROVIDED = [
  {
    display: 'Abuse and Violence Support',
    value: {
      service_types: [
        'Abuse and violence support',
        'Stopping violence services',
        'Sexual harm victims/survivors - Do you need help and support?',
        'Harmful sexual behaviour',
      ],
      programme_areas: [],
      age_groups: [],
      tags: [],
      subtypes: [],
    },
  },
  {
    display: 'Accommodation Support',
    value: {
      service_types: ['Accommodation and housing support'],
      programme_areas: [],
      age_groups: [],
      tags: [],
      subtypes: [],
    },
  },
  {
    display: 'Addiction',
    value: {
      service_types: [],
      programme_areas: [],
      age_groups: [],
      tags: [],
      subtypes: [],
      help_types: ['working-through-addiction'],
    },
  },
  {
    display: 'Counselling / Therapy',
    value: {
      service_types: [
        'Sexual harm victims/survivors - Do you need help and support?',
        'Counselling',
      ],
      programme_areas: [], // ['Mental health'],
      age_groups: [],
      tags: [],
      subtypes: [], // ['Counselling', 'Support and counselling'],
    },
  },
  {
    display: 'Disability Support Services',
    value: {
      service_types: ['Disability support services'],
      programme_areas: [],
      age_groups: [],
      tags: [],
      subtypes: [],
    },
  },
  {
    display: 'Elder Abuse',
    value: {
      service_types: ['Elder abuse'],
      programme_areas: [],
      age_groups: [],
    },
  },
  {
    display: 'Ethnic Services',
    value: {
      service_types: ['Refugee / migrant support', 'Asian services'],
      programme_areas: [],
      age_groups: [],
      tags: [],
      subtypes: [],
    },
  },
  {
    display: 'Family / Whānau support',
    value: {
      service_types: ['Family / whānau support'],
      programme_areas: [], // ['Kaupapa Māori'],
      age_groups: [],
      tags: [],
      subtypes: [], // ['Whānau support'],
    },
  },
  {
    display: 'Kaupapa Māori Services',
    value: {
      service_types: ['Kaupapa Māori'],
      programme_areas: [],
      age_groups: [],
      tags: ['kaupapa māori', 'kaupapamaori'],
      subtypes: [],
    },
  },
  {
    display: 'Legal Services',
    value: {
      service_types: ['Legal / civil services'],
      programme_areas: [],
      age_groups: [],
      tags: [],
      subtypes: [],
    },
  },
  {
    display: 'LGBTQIA+/Rainbow Support',
    value: {
      service_types: ['LGBTQIA+ support'],
      programme_areas: [],
      age_groups: [],
      tags: ['lgbtqia+ friendly'],
      subtypes: [],
    },
  },
  {
    display: 'Mental Health Support',
    value: {
      service_types: [], // ['Counselling'],
      programme_areas: [], // ['Mental health'],
      age_groups: [],
      tags: ['ngomentalhealth', 'mental health & addictions'],
      subtypes: [],
    },
  },
  {
    display: 'Pacific services',
    value: {
      service_types: ['Pacific People'],
      programme_areas: [], // ['Pacific People'],
      age_groups: [],
      tags: ['pacific people', 'pasifika'],
      subtypes: [],
    },
  },
  {
    display: 'Refuge',
    value: {
      service_types: ['Refuge'],
      programme_areas: [],
      age_groups: [],
      tags: [],
      subtypes: [],
    },
  },
  {
    display: 'Sexual violence support services',
    value: {
      service_types: [],
      programme_areas: [],
      age_groups: [],
      tags: [],
      subtypes: [],
      help_types: ['sexual-harm'],
    },
  },
  {
    display: 'Stopping violence services',
    value: {
      service_types: ['Counselling', 'Abuse and violence support', 'Stopping violence services'],
      programme_areas: [],
      age_groups: [],
      tags: [],
      subtypes: [],
    },
  },
  {
    display: 'Support for Children',
    value: {
      service_types: [
        'Family / whānau support',
        'Mother and child / Māmā and pēpi',
        'Well Child/Tamariki Ora Health Checks – Birth to Three Years',
      ],
      programme_areas: [],
      age_groups: ['child / tamariki'],
      tags: ['child / tamariki friendly'],
      subtypes: [],
    },
  },
  {
    display: 'Youth Services',
    value: {
      service_types: ['Youth mentoring / development'],
      programme_areas: [],
      age_groups: ['youth / rangatahi'],
      tags: [],
      subtypes: [],
    },
  },
];
