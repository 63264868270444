<template>
  <div
    ref="modal"
    class="advanced-search-modal"
    role="dialog"
    aria-modal="true"
    aria-labelledby="advanced-search-modal__title"
    tabindex="-1"
    v-if="open"
    @keydown.stop="handleKeyDown"
  >
    <div class="advanced-search-modal__mask" @click.stop.prevent="deactivate"></div>
    <div class="advanced-search-modal__inner">
      <div class="advanced-search-modal__header">
        <div class="advanced-search-modal__text">
          <span class="icon-sliders"></span>
          <h2 id="advanced-search-modal__title" class="advanced-search-modal__title">
            {{ title }}
          </h2>
        </div>
        <button
          class="button button--black-outlined button--small icon-close-after"
          :aria-label="`Close the ${title} modal`"
          @click.stop.prevent="deactivate"
        >
          Close
        </button>
      </div>
      <div class="advanced-search-modal__content">
        <slot name="search"></slot>

        <div class="advanced-search-modal__separator">
          <span>Select Options</span>
        </div>

        <LocationFields
          ref="location"
          region-select-icon="icon-translate"
          region-select-placeholder="Select a Region"
          region-field-label="Region"
          city-select-icon="icon-pin-map"
          city-select-placeholder="Select a Town"
          city-field-label="Town"
        ></LocationFields>

        <div class="advanced-search-modal__location-text">
          <span class="icon-information" aria-hidden="true"></span>
          Your location will only be used to improve your search in the area. It will remain
          confidential and not be stored once you leave the site.
        </div>

        <div class="advanced-search-modal__separator"></div>

        <div class="advanced-search-modal__dropdowns">
          <div class="advanced-search-modal__dropdown-field">
            <label class="advanced-search-modal__label" for="ServicesProvidedField">
              Services Provided
            </label>

            <MultiSelectField
              key="ServicesProvidedField"
              id="ServicesProvidedField"
              :options="servicesProvidedOptions"
              placeholder="Select a Category"
              :single-select="false"
              v-model="servicesProvided"
              @clear="servicesProvided = []"
              class="advanced-search-modal__dropdown-field--service-provided"
            >
            </MultiSelectField>
          </div>

          <div class="advanced-search-modal__dropdown-field">
            <label class="advanced-search-modal__label" for="ServiceFeaturesField">
              Service Features
            </label>

            <MultiSelectField
              id="ServiceFeaturesField"
              :options="serviceFeaturesOptions"
              placeholder="Select a Service"
              :single-select="false"
              v-model="serviceFeatures"
              @clear="serviceFeatures = []"
              class="advanced-search-modal__dropdown-field--service-features"
            >
            </MultiSelectField>
          </div>

          <div class="advanced-search-modal__dropdown-field">
            <label class="advanced-search-modal__label" for="languagesSelectField">
              Languages Offered
            </label>
            <!-- Languages -->
            <MultiSelectField
              id="languagesSelectField"
              :options="languageOptions"
              icon="icon-translate"
              placeholder="Select a Language"
              :single-select="false"
              :searchable="true"
              v-model="languages"
              @clear="languages = []"
              class="advanced-search-modal__dropdown-field--language"
            >
            </MultiSelectField>
          </div>
        </div>

        <div class="advanced-search-modal__checkbox-wrapper">
          <input
            type="checkbox"
            id="checkbox"
            v-model="openNow"
            role="checkbox"
            :aria-checked="openNow ?? false"
          />
          <label for="checkbox">Only show services open now</label>
        </div>

        <div class="advanced-search-modal__separator"></div>

        <div class="advanced-search-modal__buttons-wrapper">
          <button
            class="button--purple-filled button--large d-block d-lg-none"
            type="submit"
            @click="getResult"
          >
            Apply
          </button>
          <button class="button--purple-outlined button--large" type="button" @click="clearAll">
            Clear All
          </button>
          <button
            class="button--purple-filled button--large d-none d-lg-flex"
            type="submit"
            @click="getResult"
          >
            Apply Filters & Search
          </button>
        </div>
      </div>
      <div class="advanced-search-modal__footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LocationFields from '@/components/LocationFields.vue';
import { SERVICES_PROVIDED } from '@/constants/services-provided';
import { SERVICES_FEATURED } from '@/constants/services-features';
import MultiSelectField from './MultiSelectField.vue';

export default {
  name: 'AdvancedSearchModal',
  emits: ['close', 'clearKeyword'],
  components: {
    LocationFields,
    MultiSelectField,
  },
  props: {
    /**
     * If the modal should be open
     */
    open: {
      type: Boolean,
      default: false,
    },
    /**
     * The title of the modal
     */
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      serviceFeaturesOptions: SERVICES_FEATURED,
      servicesProvidedOptions: SERVICES_PROVIDED,
    };
  },
  watch: {
    open(newVal) {
      if (newVal) {
        // Apply class to body to restrict scroll
        document.body.classList.add('body--modal-open');

        // Focus the user in the modal
        setTimeout(() => {
          this.$refs.modal.focus();
        }, 0);
      } else {
        // Remove class from body which restricts scroll
        document.body.classList.remove('body--modal-open');
      }
    },
  },
  computed: {
    ...mapState({
      languageOptions: (state) => state.filters.languages,
    }),
    openNow: {
      get() {
        return this.$store.state.filters.selected.openNow;
      },
      set(value) {
        this.$store.commit('filters/setOpenNow', value);
      },
    },
    languages: {
      get() {
        return this.$store.state.filters.selected.languages;
      },
      set(value) {
        this.$store.commit('filters/setSelectedLanguages', value);
      },
    },
    servicesProvided: {
      get() {
        return this.$store.state.filters.selected.servicesProvided;
      },
      set(value) {
        this.$store.commit('filters/setSelectedServicesProvided', value);

        // Handle any attached help types
        const hasHelpTypes = value.filter((sp) => {
          return sp.value.help_types?.length;
        });

        if (hasHelpTypes.length) {
          const helpTypes = [];

          hasHelpTypes.forEach((ht) => {
            helpTypes.push(...ht.value.help_types);
          });

          this.$store.commit('filters/selectHelpTypes', helpTypes);
        }
      },
    },
    serviceFeatures: {
      get() {
        return this.$store.state.filters.selected.serviceFeatures;
      },
      set(value) {
        this.$store.commit('filters/setSelectedServiceFeatures', value);
      },
    },
  },
  methods: {
    deactivate() {
      this.$emit('close', true);
    },
    clearAll() {
      this.languages = [];
      this.serviceFeatures = [];
      this.servicesProvided = [];
      this.openNow = false;
      this.$refs.location.clearAll();
      this.$store.commit('filters/setKeyword', '');
    },
    getResult() {
      this.$root.fetchFreshResults();
      this.$router.push('/results');

      this.$nextTick(() => {
        this.$emit('close', true);
      });
    },
    handleKeyDown(ev) {
      if (!ev.shiftKey) {
        if (ev.key === 'Tab') {
          this.handleTab(ev);
        } else if (ev.key === 'Escape') {
          this.deactivate();
        }
      } else if (ev.key === 'Tab') {
        this.handleShiftTab(ev);
      }
    },
    handleTab(ev) {
      const elements = this.focusableElements();
      const first = elements[0];
      const last = elements[elements.length - 1];

      if (document.activeElement === last) {
        first.focus();
        ev.preventDefault();
      }
    },
    handleShiftTab(ev) {
      const elements = this.focusableElements();
      const first = elements[0];
      const last = elements[elements.length - 1];

      if (document.activeElement === first) {
        last.focus();
        ev.preventDefault();
      }
    },
    focusableElements() {
      const selector = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

      return this.$refs.modal.querySelectorAll(selector);
    },
  },
};
</script>
