export default {
  methods: {
    sendGTMEvent(eventName, data = {}) {
      if (!window.dataLayer) {
        return;
      }

      window.dataLayer.push({
        event: eventName,
        ...data,
      });
    },
  },
};
