import { createStore } from 'vuex';

import filters from './filters';
import results from './results';

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    filters,
    results,
  },
});
