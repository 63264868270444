<template>
  <div class="search-field-wrapper" :class="wrapperClass">
    <div
      class="search-field w-100"
      :class="{ 'is-bare': !showSearchButton && !showAdvancedSearch }"
    >
      <div class="search-field__search-icon" v-if="showSearchIcon">
        <span class="icon-search"></span>
      </div>
      <input
        class="search-field__input"
        :class="{
          'has-icon': showSearchIcon,
          'is-bare': !showAdvancedSearch && !showSearchButton && !showCloseIcon,
        }"
        v-model="keyword"
        :placeholder="placeHolderText ?? 'Search for keyword, provider, or location'"
        @keydown.enter="getResult"
      />
      <div
        class="search-field__actions d-flex column-gap-1"
        v-if="showAdvancedSearch || showSearchButton || showCloseIcon"
      >
        <button
          @click.prevent="goToAdvancedSearch"
          class="button search-field__icon-sliders button--purple-text button--icon-only-round icon-sliders"
          aria-label="Go to advanced search"
          v-if="showAdvancedSearch"
        ></button>
        <button
          @click.prevent="getResult"
          class="button search-field__icon-search button--purple-filled button--icon-only-round icon-search"
          aria-label="Search"
          v-if="showSearchButton"
        ></button>
        <button
          @click.prevent="cancelAndNotifyParent"
          class="button button--link-transparent button--icon-only-round icon-close"
          aria-label="Close and go back"
          v-if="showCloseIcon"
        ></button>
      </div>
    </div>
    <div class="search-field__help-text" v-if="showHelpText">
      <span class="icon-information" aria-hidden="true"></span>
      You can search by location, service or Keyword
    </div>
  </div>

  <AdvancedSearchModal
    :open="advancedSearchModalOpen"
    title="Advanced Search"
    @close="closeAdvancedSearchModal"
    v-if="showAdvancedSearch"
  >
    <template #search>
      <input
        id="keyword-search"
        class="icon-search advanced-search-modal__input"
        v-model="keyword"
        placeholder="Search for services"
        @keydown.enter="getResult"
      />
      <button
        @click="getResult"
        class="advanced-search-modal__search icon-search"
        aria-label="Search"
      ></button>
    </template>
  </AdvancedSearchModal>
</template>

<script>
import { mapMutations } from 'vuex';
import AdvancedSearchModal from '@/components/AdvancedSearchModal';

export default {
  name: 'SearchField',
  components: {
    AdvancedSearchModal,
  },
  props: {
    showAdvancedSearch: {
      type: Boolean,
      default: true,
    },
    showSearchButton: {
      type: Boolean,
      default: true,
    },
    showSearchIcon: {
      type: Boolean,
      default: false,
    },
    showCloseIcon: {
      type: Boolean,
      default: false,
    },
    wrapperClass: {
      type: String,
      required: false,
      default: '',
    },
    placeHolderText: {
      type: String,
      required: false,
    },
    showHelpText: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      advancedSearchModalOpen: false,
    };
  },
  computed: {
    keyword: {
      get() {
        return this.$store.state.filters.keyword.live;
      },
      set(value) {
        this.$store.commit('filters/setKeyword', value);
      },
    },
  },
  emits: ['searchFieldIsDeactivated'],
  methods: {
    closeAdvancedSearchModal() {
      this.advancedSearchModalOpen = false;
    },
    getResult() {
      this.applyKeywordChanges();

      if (this.$route.name === 'results') {
        this.$root.fetchFreshResults();
      }

      this.$router.push({ name: 'results', query: { q: this.keyword } });
      this.closeAdvancedSearchModal();
    },
    goToAdvancedSearch() {
      this.advancedSearchModalOpen = true;
    },
    cancelAndNotifyParent() {
      this.keyword = '';
      this.$emit('searchFieldIsDeactivated', true);
    },
    ...mapMutations('filters', ['applyKeywordChanges']),
  },
};
</script>
