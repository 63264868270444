import { createApp } from 'vue';
import { createGtm } from '@gtm-support/vue-gtm';
import API from '@/api';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import AnalyticsMixin from '@/mixins/analytics';
import embeddedMixin from '@/mixins/embedded';
import VueAnnouncer from '@vue-a11y/announcer';

import '@/scss/main.scss';

const app = createApp(App);

app.mixin(AnalyticsMixin);
app.mixin(embeddedMixin);

app.use(VueAnnouncer).use(store).use(router).use(API);

if (process.env.VUE_APP_GTM_ID && process.env.VUE_APP_GTM_ID.trim() !== '') {
  app.use(
    createGtm({
      id: process.env.VUE_APP_GTM_ID,
      vueRouter: router,
    })
  );
}

app.mount('#app');

window.addEventListener('keydown', (e) => {
  if (e.key === 'Escape') {
    App.methods.exitTool();
  }
});
