/**
 * A list of towns and cities with latitude / longitude information.
 *
 * Sourced from the LINZ Place Names database, filtered to `feat_type` values of 'Town' or 'City',
 * and further groomed to remove some duplicated place names.
 *
 * LINZ Place Names database: https://data.linz.govt.nz/layer/51681-nz-place-names-nzgb/data/
 */
export const TOWNS_CITIES = [
  {
    name: 'Ahipara',
    latitude: -35.1643,
    longitude: 173.156,
    type: 'Town',
  },
  {
    name: 'Akaroa',
    latitude: -43.8052,
    longitude: 172.967,
    type: 'Town',
  },
  {
    name: 'Aoraki/Mount Cook',
    latitude: -43.7351,
    longitude: 170.098,
    type: 'Town',
  },
  {
    name: 'Ashburton',
    latitude: -43.9054,
    longitude: 171.745,
    type: 'Town',
  },
  {
    name: 'Auckland',
    latitude: -36.8525,
    longitude: 174.762,
    type: 'City',
  },
  {
    name: 'Balclutha',
    latitude: -46.2384,
    longitude: 169.739,
    type: 'Town',
  },
  {
    name: 'Bell Block',
    latitude: -39.0278,
    longitude: 174.15,
    type: 'Town',
  },
  {
    name: 'Blenheim',
    latitude: -41.5123,
    longitude: 173.958,
    type: 'Town',
  },
  {
    name: 'Bluff',
    latitude: -46.6006,
    longitude: 168.34,
    type: 'Town',
  },
  {
    name: 'Carterton',
    latitude: -41.0242,
    longitude: 175.529,
    type: 'Town',
  },
  {
    name: 'Christchurch',
    latitude: -43.531,
    longitude: 172.636,
    type: 'City',
  },
  {
    name: 'Clive',
    latitude: -39.5867,
    longitude: 176.913,
    type: 'Town',
  },
  {
    name: 'Colac Bay/Ōraka',
    latitude: -46.3622,
    longitude: 167.876,
    type: 'Town',
  },
  {
    name: 'Dannevirke',
    latitude: -40.2119,
    longitude: 176.095,
    type: 'Town',
  },
  {
    name: 'Dargaville',
    latitude: -35.9413,
    longitude: 173.869,
    type: 'Town',
  },
  {
    name: 'Dunedin',
    latitude: -45.8743,
    longitude: 170.503,
    type: 'City',
  },
  {
    name: 'Featherston',
    latitude: -41.1169,
    longitude: 175.326,
    type: 'Town',
  },
  {
    name: 'Flat Bush',
    latitude: -36.9662,
    longitude: 174.914,
    type: 'Town',
  },
  {
    name: 'Foxton Beach',
    latitude: -40.4646,
    longitude: 175.231,
    type: 'Town',
  },
  {
    name: 'Frankton',
    latitude: -45.0235,
    longitude: 168.736,
    type: 'Town',
  },
  {
    name: 'Gisborne',
    latitude: -38.6619,
    longitude: 178.019,
    type: 'Town',
  },
  {
    name: 'Granity',
    latitude: -41.6292,
    longitude: 171.855,
    type: 'Town',
  },
  {
    name: 'Greta Valley',
    latitude: -42.9651,
    longitude: 172.971,
    type: 'Town',
  },
  {
    name: 'Greymouth',
    latitude: -42.4511,
    longitude: 171.207,
    type: 'Town',
  },
  {
    name: 'Greytown',
    latitude: -41.0799,
    longitude: 175.461,
    type: 'Town',
  },
  {
    name: 'Haast',
    latitude: -43.881,
    longitude: 169.04,
    type: 'Town',
  },
  {
    name: 'Haast Beach',
    latitude: -43.8643,
    longitude: 168.997,
    type: 'Town',
  },
  {
    name: 'Hamilton',
    latitude: -37.7808,
    longitude: 175.273,
    type: 'City',
  },
  {
    name: 'Hampden',
    latitude: -41.8003,
    longitude: 172.326,
    type: 'Town',
  },
  {
    name: 'Hanmer',
    latitude: -42.5242,
    longitude: 172.831,
    type: 'Town',
  },
  {
    name: 'Hanmer Springs',
    latitude: -42.5242,
    longitude: 172.831,
    type: 'Town',
  },
  {
    name: 'Hastings',
    latitude: -39.639,
    longitude: 176.847,
    type: 'City',
  },
  {
    name: 'Haumoana',
    latitude: -39.6103,
    longitude: 176.952,
    type: 'Town',
  },
  {
    name: 'Havelock North',
    latitude: -39.6695,
    longitude: 176.877,
    type: 'Town',
  },
  {
    name: 'Hāwera',
    latitude: -39.589,
    longitude: 174.282,
    type: 'Town',
  },
  {
    name: 'Hikurangi',
    latitude: -35.6007,
    longitude: 174.287,
    type: 'Town',
  },
  {
    name: 'Hokitika',
    latitude: -42.7172,
    longitude: 170.964,
    type: 'Town',
  },
  {
    name: 'Invercargill',
    latitude: -46.4287,
    longitude: 168.362,
    type: 'City',
  },
  {
    name: 'Kaikohe',
    latitude: -35.4066,
    longitude: 173.802,
    type: 'Town',
  },
  {
    name: 'Kaikōura',
    latitude: -42.4107,
    longitude: 173.686,
    type: 'Town',
  },
  {
    name: 'Kaitaia',
    latitude: -35.1141,
    longitude: 173.264,
    type: 'Town',
  },
  {
    name: 'Katikati',
    latitude: -37.5517,
    longitude: 175.918,
    type: 'Town',
  },
  {
    name: 'Kawakawa',
    latitude: -35.3833,
    longitude: 174.068,
    type: 'Town',
  },
  {
    name: 'Kawerau',
    latitude: -38.0847,
    longitude: 176.702,
    type: 'Town',
  },
  {
    name: 'Kawhia',
    latitude: -38.066,
    longitude: 174.819,
    type: 'Town',
  },
  {
    name: 'Kerikeri',
    latitude: -35.2278,
    longitude: 173.955,
    type: 'Town',
  },
  {
    name: 'Kihikihi',
    latitude: -38.0379,
    longitude: 175.346,
    type: 'Town',
  },
  {
    name: 'Kumeū',
    latitude: -36.7771,
    longitude: 174.559,
    type: 'Town',
  },
  {
    name: 'Kuratau',
    latitude: -38.8924,
    longitude: 175.77,
    type: 'Town',
  },
  {
    name: 'Levin',
    latitude: -40.6204,
    longitude: 175.285,
    type: 'Town',
  },
  {
    name: 'Lower Hutt',
    latitude: -41.2087,
    longitude: 174.906,
    type: 'City',
  },
  {
    name: 'Lyttelton',
    latitude: -43.6034,
    longitude: 172.72,
    type: 'Town',
  },
  {
    name: 'Makarewa',
    latitude: -46.3322,
    longitude: 168.347,
    type: 'Town',
  },
  {
    name: 'Makerewa Township',
    latitude: -46.3322,
    longitude: 168.347,
    type: 'Town',
  },
  {
    name: 'Manaia',
    latitude: -39.551,
    longitude: 174.125,
    type: 'Town',
  },
  {
    name: 'Manawatu Heads',
    latitude: -40.4646,
    longitude: 175.231,
    type: 'Town',
  },
  {
    name: 'Mangakino',
    latitude: -38.3688,
    longitude: 175.775,
    type: 'Town',
  },
  {
    name: 'Mangawhai Heads',
    latitude: -36.0996,
    longitude: 174.589,
    type: 'Town',
  },
  {
    name: 'Manukau',
    latitude: -36.9988,
    longitude: 174.864,
    type: 'City',
  },
  {
    name: 'Martinborough',
    latitude: -41.2185,
    longitude: 175.459,
    type: 'Town',
  },
  {
    name: 'Masterton',
    latitude: -40.9519,
    longitude: 175.661,
    type: 'Town',
  },
  {
    name: 'Matamata',
    latitude: -37.811,
    longitude: 175.773,
    type: 'Town',
  },
  {
    name: 'Matatā',
    latitude: -37.8902,
    longitude: 176.758,
    type: 'Town',
  },
  {
    name: 'Minginui',
    latitude: -38.643,
    longitude: 176.732,
    type: 'Town',
  },
  {
    name: 'Moerewa',
    latitude: -35.3868,
    longitude: 174.019,
    type: 'Town',
  },
  {
    name: 'Mosgiel',
    latitude: -45.8754,
    longitude: 170.348,
    type: 'Town',
  },
  {
    name: 'Motueka',
    latitude: -41.1108,
    longitude: 173.012,
    type: 'Town',
  },
  {
    name: 'Motuoapa',
    latitude: -38.9329,
    longitude: 175.874,
    type: 'Town',
  },
  {
    name: 'Motuopa',
    latitude: -38.9329,
    longitude: 175.874,
    type: 'Town',
  },
  {
    name: 'Mount Cook',
    latitude: -43.7351,
    longitude: 170.098,
    type: 'Town',
  },
  {
    name: 'Mount Maunganui',
    latitude: -37.6434,
    longitude: 176.188,
    type: 'Town',
  },
  {
    name: 'Murapara',
    latitude: -38.4564,
    longitude: 176.705,
    type: 'Town',
  },
  {
    name: 'Murchison',
    latitude: -41.8003,
    longitude: 172.326,
    type: 'Town',
  },
  {
    name: 'Murupara',
    latitude: -38.4564,
    longitude: 176.705,
    type: 'Town',
  },
  {
    name: 'Napier',
    latitude: -39.5025,
    longitude: 176.893,
    type: 'City',
  },
  {
    name: 'Nelson',
    latitude: -41.2735,
    longitude: 173.282,
    type: 'City',
  },
  {
    name: 'New Plymouth',
    latitude: -39.056,
    longitude: 174.083,
    type: 'City',
  },
  {
    name: 'Ngāruawāhia',
    latitude: -37.6684,
    longitude: 175.147,
    type: 'Town',
  },
  {
    name: 'Ōakura',
    latitude: -39.1139,
    longitude: 173.953,
    type: 'Town',
  },
  {
    name: 'Oamaru',
    latitude: -45.0973,
    longitude: 170.972,
    type: 'Town',
  },
  {
    name: 'Ohakune',
    latitude: -39.4178,
    longitude: 175.4,
    type: 'Town',
  },
  {
    name: 'Ōpōtiki',
    latitude: -38.0066,
    longitude: 177.287,
    type: 'Town',
  },
  {
    name: 'Ōpunake',
    latitude: -39.4544,
    longitude: 173.859,
    type: 'Town',
  },
  {
    name: 'Ōtaki',
    latitude: -40.7528,
    longitude: 175.14,
    type: 'Town',
  },
  {
    name: 'Ōtaki Beach',
    latitude: -40.7401,
    longitude: 175.118,
    type: 'Town',
  },
  {
    name: 'Otematata',
    latitude: -44.6043,
    longitude: 170.192,
    type: 'Town',
  },
  {
    name: 'Owaka',
    latitude: -46.452,
    longitude: 169.661,
    type: 'Town',
  },
  {
    name: 'Oxford',
    latitude: -37.9793,
    longitude: 175.76,
    type: 'Town',
  },
  {
    name: 'Paekākāriki',
    latitude: -40.9831,
    longitude: 174.954,
    type: 'Town',
  },
  {
    name: 'Paeroa',
    latitude: -37.3832,
    longitude: 175.674,
    type: 'Town',
  },
  {
    name: 'Paihia',
    latitude: -35.2833,
    longitude: 174.092,
    type: 'Town',
  },
  {
    name: 'Palmerston',
    latitude: -45.4835,
    longitude: 170.715,
    type: 'Town',
  },
  {
    name: 'Palmerston North',
    latitude: -40.3557,
    longitude: 175.617,
    type: 'City',
  },
  {
    name: 'Parakai',
    latitude: -36.6604,
    longitude: 174.433,
    type: 'Town',
  },
  {
    name: 'Paraparaumu',
    latitude: -40.9064,
    longitude: 175.007,
    type: 'Town',
  },
  {
    name: 'Paraparaumu Beach',
    latitude: -40.8882,
    longitude: 174.983,
    type: 'Town',
  },
  {
    name: 'Pembroke',
    latitude: -44.7012,
    longitude: 169.13,
    type: 'Town',
  },
  {
    name: 'Picton',
    latitude: -41.2907,
    longitude: 174.007,
    type: 'Town',
  },
  {
    name: 'Pōkeno',
    latitude: -37.2434,
    longitude: 175.018,
    type: 'Town',
  },
  {
    name: 'Porirua',
    latitude: -41.1359,
    longitude: 174.838,
    type: 'City',
  },
  {
    name: 'Port Chalmers',
    latitude: -45.8187,
    longitude: 170.621,
    type: 'Town',
  },
  {
    name: 'Pukekohe',
    latitude: -37.2031,
    longitude: 174.904,
    type: 'Town',
  },
  {
    name: 'Putāruru',
    latitude: -38.0527,
    longitude: 175.78,
    type: 'Town',
  },
  {
    name: 'Queenstown',
    latitude: -45.0323,
    longitude: 168.661,
    type: 'Town',
  },
  {
    name: 'Raetihi',
    latitude: -39.4265,
    longitude: 175.279,
    type: 'Town',
  },
  {
    name: 'Rakaia',
    latitude: -43.7556,
    longitude: 172.025,
    type: 'Town',
  },
  {
    name: 'Rangitata',
    latitude: -44.0659,
    longitude: 171.373,
    type: 'Town',
  },
  {
    name: 'Raumati Beach',
    latitude: -40.9187,
    longitude: 174.981,
    type: 'Town',
  },
  {
    name: 'Raumati South',
    latitude: -40.9363,
    longitude: 174.977,
    type: 'Town',
  },
  {
    name: 'Reefton',
    latitude: -42.1168,
    longitude: 171.862,
    type: 'Town',
  },
  {
    name: 'Richmond',
    latitude: -41.3392,
    longitude: 173.185,
    type: 'Town',
  },
  {
    name: 'Riverton',
    latitude: -46.3526,
    longitude: 168.015,
    type: 'Town',
  },
  {
    name: 'Riverton/Aparima',
    latitude: -46.3526,
    longitude: 168.015,
    type: 'Town',
  },
  {
    name: 'Rotorua',
    latitude: -38.1375,
    longitude: 176.252,
    type: 'City',
  },
  {
    name: 'South Rakaia',
    latitude: -43.7556,
    longitude: 172.025,
    type: 'Town',
  },
  {
    name: 'South Township',
    latitude: -44.0659,
    longitude: 171.373,
    type: 'Town',
  },
  {
    name: 'Stoke',
    latitude: -41.3166,
    longitude: 173.232,
    type: 'Town',
  },
  {
    name: 'Stratford',
    latitude: -39.3409,
    longitude: 174.284,
    type: 'Town',
  },
  {
    name: 'Taihape',
    latitude: -39.6775,
    longitude: 175.797,
    type: 'Town',
  },
  {
    name: 'Tauhara',
    latitude: -38.7275,
    longitude: 176.071,
    type: 'Town',
  },
  {
    name: 'Taumarunui',
    latitude: -38.8832,
    longitude: 175.259,
    type: 'Town',
  },
  {
    name: 'Taupahi',
    latitude: -38.9885,
    longitude: 175.81,
    type: 'Town',
  },
  {
    name: 'Taupō',
    latitude: -38.6862,
    longitude: 176.071,
    type: 'Town',
  },
  {
    name: 'Tauranga',
    latitude: -37.6787,
    longitude: 176.167,
    type: 'City',
  },
  {
    name: 'Te Aroha',
    latitude: -37.5414,
    longitude: 175.711,
    type: 'Town',
  },
  {
    name: 'Te Awamutu',
    latitude: -38.0103,
    longitude: 175.324,
    type: 'Town',
  },
  {
    name: 'Te Awanga',
    latitude: -39.6338,
    longitude: 176.981,
    type: 'Town',
  },
  {
    name: 'Te Kuiti',
    latitude: -38.3337,
    longitude: 175.167,
    type: 'Town',
  },
  {
    name: 'Te Puke',
    latitude: -37.7851,
    longitude: 176.319,
    type: 'Town',
  },
  {
    name: 'Temple View',
    latitude: -37.8208,
    longitude: 175.225,
    type: 'Town',
  },
  {
    name: 'Thames',
    latitude: -37.1384,
    longitude: 175.541,
    type: 'Town',
  },
  {
    name: 'Timaru',
    latitude: -44.3984,
    longitude: 171.255,
    type: 'Town',
  },
  {
    name: 'Tinwald',
    latitude: -43.9196,
    longitude: 171.721,
    type: 'Town',
  },
  {
    name: 'Tīrau',
    latitude: -37.9793,
    longitude: 175.76,
    type: 'Town',
  },
  {
    name: 'Tokoroa',
    latitude: -38.2232,
    longitude: 175.866,
    type: 'Town',
  },
  {
    name: 'Torea',
    latitude: -41.6292,
    longitude: 171.855,
    type: 'Town',
  },
  {
    name: 'Tuakau',
    latitude: -37.2585,
    longitude: 174.945,
    type: 'Town',
  },
  {
    name: 'Tūrangi',
    latitude: -38.9885,
    longitude: 175.81,
    type: 'Town',
  },
  {
    name: 'Twizel',
    latitude: -44.2578,
    longitude: 170.097,
    type: 'Town',
  },
  {
    name: 'Upper Hutt',
    latitude: -41.1245,
    longitude: 175.069,
    type: 'Town',
  },
  {
    name: 'Waikanae',
    latitude: -40.8763,
    longitude: 175.066,
    type: 'Town',
  },
  {
    name: 'Waikanae Beach',
    latitude: -40.8659,
    longitude: 175.026,
    type: 'Town',
  },
  {
    name: 'Waimate',
    latitude: -44.7328,
    longitude: 171.047,
    type: 'Town',
  },
  {
    name: 'Waimauku',
    latitude: -36.7693,
    longitude: 174.492,
    type: 'Town',
  },
  {
    name: 'Wainuiomata',
    latitude: -41.261,
    longitude: 174.945,
    type: 'Town',
  },
  {
    name: 'Waiouru',
    latitude: -39.4685,
    longitude: 175.676,
    type: 'Town',
  },
  {
    name: 'Waipukurau',
    latitude: -39.9963,
    longitude: 176.554,
    type: 'Town',
  },
  {
    name: 'Wairoa',
    latitude: -39.0415,
    longitude: 177.424,
    type: 'Town',
  },
  {
    name: 'Waitara', // Also known as Te Whai-taranui-a-Whare-Matangi-ki-te-kimi-i-tana-matua-a-Ngarue
    latitude: -38.997,
    longitude: 174.231,
    type: 'Town',
  },
  {
    name: 'Waiuku',
    latitude: -37.2502,
    longitude: 174.727,
    type: 'Town',
  },
  {
    name: 'Wānaka',
    latitude: -44.7012,
    longitude: 169.13,
    type: 'Town',
  },
  {
    name: 'Wanganui',
    latitude: -39.933,
    longitude: 175.028,
    type: 'City',
  },
  {
    name: 'Warkworth',
    latitude: -36.4005,
    longitude: 174.664,
    type: 'Town',
  },
  {
    name: 'Wataroa',
    latitude: -43.2632,
    longitude: 170.365,
    type: 'Town',
  },
  {
    name: 'Wellington',
    latitude: -41.2856,
    longitude: 174.777,
    type: 'City',
  },
  {
    name: 'Westport',
    latitude: -41.7553,
    longitude: 171.599,
    type: 'Town',
  },
  {
    name: 'Whakatāne',
    latitude: -37.9643,
    longitude: 176.984,
    type: 'Town',
  },
  {
    name: 'Whangamatā',
    latitude: -37.2096,
    longitude: 175.874,
    type: 'Town',
  },
  {
    name: 'Whanganui',
    latitude: -39.933,
    longitude: 175.028,
    type: 'City',
  },
  {
    name: 'Whangaparāoa',
    latitude: -36.6349,
    longitude: 174.748,
    type: 'Town',
  },
  {
    name: 'Whangārei',
    latitude: -35.7247,
    longitude: 174.32,
    type: 'City',
  },
  {
    name: 'Wharewaka',
    latitude: -38.7275,
    longitude: 176.071,
    type: 'Town',
  },
  {
    name: 'Whataroa',
    latitude: -43.2632,
    longitude: 170.365,
    type: 'Town',
  },
];

/**
 * A list of regions in NZ, with geolocation data (based on the centre of the region)
 *
 * @todo Source this data
 */
export const REGIONS = [];
